<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('external_research.fund_request') + ' ' + $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:body>
        <b-overlay :show="searchLoader">
          <b-row>
              <b-col lg="6" md="6" sm="12" xs="12">
                <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('research_manage.thematic_area')"
                label-for="thematic_area_id"
                >
                <b-form-select
                    plain
                    v-model="search.thematic_area_id"
                    :options="thematicAreaList"
                    id="thematic_area_id"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                    </template>
                </b-form-select>
                </b-form-group>
            </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group
                  class="row"
                  label-cols-sm="4"
                  :label="$t('external_research.project_title')"
                  label-for="project_title"
                  >
                  <b-form-select
                    plain
                    v-model="search.project_title"
                    id="project_title"
                    :options="projectList"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                  </b-form-group>
              </b-col>
            <b-col lg="6" md="6" sm="12" xs="12">
                <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('research_manage.project_id')"
                label-for="project_status"
                >
                <b-form-input
                    plain
                    v-model="search.project_id"
                    id="project_status"
                    >
                </b-form-input>
                </b-form-group>
            </b-col>
              <!-- <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <b-form-group
                  class="row"
                  label-cols-sm="4"
                  :label="$t('external_research.budget_head')"
                  label-for="budget_head"
                  >
                  <b-form-select
                    plain
                    v-model="search.budget_head_id"
                    id="budget_head_id"
                    :options="budgetHeadList"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                  </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <b-form-group
                  class="row"
                  label-cols-sm="4"
                  :label="$t('external_research.fiscal_year')"
                  label-for="fiscal_year_id"
                  >
                  <b-form-select
                    plain
                    v-model="search.fiscal_year_id"
                    id="fiscal_year_id"
                    :options="fiscalYearList"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                  </b-form-group>
              </b-col> -->
              <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <!-- <div class="text-right"> -->
                  <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                <!-- </div> -->
              </b-col>
          </b-row>
        </b-overlay>
      </template>
    </iq-card>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('external_research.fund_request') + ' ' + $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
        <b-button variant="primary" v-b-modal.modal-3 @click="resetId">
            {{  $t('globalTrans.add_new') }}
        </b-button>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <b-row>
            <b-col md="12" class="table-responsive">
              <b-table thead-class="bg-primary" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>

                <template v-slot:cell(index)="data">
                  {{ $n(data.index + pagination.slOffset) }}
                </template>

                <template v-slot:cell(action)="data">
                  <b-button v-b-modal.modal-1 variant=" iq-bg-success mr-1 mb-1" size="sm" title="View" @click="details(data.item)">
                    <i class="ri-eye-line m-0"></i>
                  </b-button>
                </template>

              </b-table>

              <b-pagination
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total-rows="pagination.totalRows"
                @input="searchData"
              />
            </b-col>
          </b-row>
        </b-overlay>
      </template>
    </iq-card>

    <!-- Form -->
    <b-modal id="modal-3" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Form :projectList="projectList" />
    </b-modal>

    <!-- Details -->
    <b-modal id="modal-1" size="lg" :title="detailsTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Details :id="viewItemId" :key="viewItemId"/>
    </b-modal>

  </b-container>
</template>
<script>

import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { fundRequestListApi, projectListApi } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'
import Form from './Form'
import Details from './Details'

export default {
  mixins: [ModalBaseMasterList],
  components: {
      Form, Details
  },
  data () {
    return {
      rows: [],
      item: '',
      search: {
        project_title: 0,
        project_id: '',
        thematic_area_id: 0
      },
      viewItemId: 0,
      projectList: [],
      searchLoader: false
    }
  },
  created () {
    this.getProjectList()
    this.loadData()
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    thematicAreaList: function () {
        return this.$store.state.ExternalAgriResearch.agriResearchCommonObj.thematicAreaList.filter(item => item.status === 1)
    },
    formTitle () {
      return this.$t('external_research.fund_request') + ' ' + this.$t('globalTrans.entry')
    },
    detailsTitle () {
      return this.$t('external_research.fund_request') + ' ' + this.$t('globalTrans.details')
    },
    currentLocale () {
      return this.$i18n.locale
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('external_research.project_title'), class: 'text-left' },
          { label: this.$t('external_research.proposal_id'), class: 'text-left' },
          { label: this.$t('external_research.thematic_area'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'project_title_bn' },
          { key: 'proposal_auto_id' },
          { key: 'thematic_area_name_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'project_title' },
          { key: 'proposal_auto_id' },
          { key: 'thematic_area_name' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  methods: {
    redirectToActivities (proposalId) {
      this.$router.push({ path: '/research-farmer/research-management/activity-plan-activities', query: { id: proposalId } })
    },
    searchData () {
      this.loadData()
    },
    details (item) {
      this.viewItemId = item.id
    },
    async getProjectList () {
      this.searchLoader = true
      const params = { proposal_status: 2, external: true }
      const result = await RestApi.getData(agriResearchServiceBaseUrl, projectListApi, params)

      if (result.success && result.data) {
        this.projectList = result.data.map((obj, key) => {
          return { ...obj, text: this.currentLocale === 'en' ? obj.text_en : obj.text_bn }
        })
      }

      this.searchLoader = false
    },
    loadData () {
      const params = Object.assign({}, this.search, { external: true }, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })

      RestApi.getData(agriResearchServiceBaseUrl, fundRequestListApi, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelationalData(response.data.data))
          this.paginationData(response.data)
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelationalData (data) {
      const listData = data.map(item => {
        // Relational
        const thematicArea = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.thematicAreaList.find(el => el.value === item.thematic_area_id)
        // const budgetHead = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.budgetHeadList.find(el => el.value === item.budget_head_id)
        let status = ''
        if (item.status === 1) {
          status = this.$t('globalTrans.pending')
        } else if (item.status === 2) {
          status = this.$t('globalTrans.approved')
        } else if (item.status === 3) {
          status = this.$t('globalTrans.rejected')
        } else {
          status = this.$t('external_research.payment')
        }

        // Evaluate
        const evaluationData = {
          project_title: item.project_title === undefined ? null : item.project_title,
          project_title_bn: item.project_title_bn === undefined ? null : item.project_title_bn,
          proposal_auto_id: item.proposal_auto_id === undefined ? null : item.proposal_auto_id,
          thematic_area_name: thematicArea === undefined ? null : thematicArea.text_en,
          thematic_area_name_bn: thematicArea === undefined ? null : thematicArea.text_bn,
          // budget_head: budgetHead === undefined ? null : budgetHead.text_en,
          // budget_head_bn: budgetHead === undefined ? null : budgetHead.text_bn,
          status: status
        }

        // Assign
        return Object.assign({}, item, evaluationData)
      })
      return listData
    }
    /** END */
  }
}
</script>
