<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <b-overlay :show="formLoader">
          <ValidationObserver ref="form">
            <b-form>
                <b-row>
                  <b-col sm="4">
                      <ValidationProvider name="Project Id" vid="project_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="project_id "
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{$t('external_research.project_title')}}<span class="text-danger">*</span>
                              </template>
                              <b-form-select
                              plain
                              v-model.number="fund_request.proposal_main_id"
                              id="proposal_main_id"
                              :options="projectList"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                      </ValidationProvider>
                  </b-col>
                  <b-col sm="4">
                      <ValidationProvider name="Project Auto Id" vid="proposal_auto_id" rules="required|min_value:1">
                        <b-form-group class="row" label-cols-sm="12" label-for="proposal_auto_id" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                            {{$t('external_research.project_id')}}
                            </template>
                            <b-form-input
                                readonly
                                plain
                                id="proposal_auto_id"
                                v-model="fund_request.proposal_auto_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </b-col>
                  <b-col sm="4">
                      <ValidationProvider name="Thematic area" vid="thematic_area" rules="required">
                        <b-form-group class="row" label-cols-sm="12" label-for="thematic_area" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                            {{$t('external_research.thematic_area')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                readonly
                                plain
                                id="thematic_area"
                                v-model="fund_request.thematic_area_title"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </b-col>
                </b-row>
            </b-form>
          </ValidationObserver>
          <div class="mb-3">
              <h5 class="ex_form_title"> {{ $t('external_research.fund_request') }}</h5>
          </div>
          <ValidationObserver ref="form1" v-slot="{ handleSubmit, reset }">
              <b-form @submit.prevent="handleSubmit(addItem)" @reset.prevent="reset">
                <b-row>
                  <b-col sm="4">
                      <ValidationProvider name="Budget Type" vid="budget_type_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="budget_type_id"
                              slot-scope="{ valid, errors }"
                          >
                              <template v-slot:label>
                                  {{$t('research_manage.budget_type')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="budget_details.budget_type_id"
                                  :options="budgetTypeList"
                                  id="budget_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0" disabled >{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                          </b-form-group>
                      </ValidationProvider>
                  </b-col>
                  <b-col sm="4">
                      <ValidationProvider name="Budged Head" vid="budget_head_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="budget_head_id "
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{$t('external_research.budget_head')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                              plain
                              v-model.number="budget_details.budget_head_id"
                              id="budget_head_id"
                              :options="budgetHeadList"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                      </ValidationProvider>
                  </b-col>
                  <b-col sm="4">
                      <ValidationProvider name="Fiscal Year" vid="fiscal_year" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="fiscal_year_id "
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{$t('external_research.fiscal_year')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                              plain
                              v-model.number="budget_details.fiscal_year_id"
                              id="fiscal_year_id"
                              :options="fiscalYearList"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                      </ValidationProvider>
                  </b-col>
                  <!-- Allocation Amount -->
                  <b-col sm="4">
                      <ValidationProvider name="Allocation Amount" vid="allocated_amount" rules="required|min_value:1">
                        <b-form-group class="row" label-cols-sm="12" label-for="allocated_amount" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                            {{$t('external_research.allocated_amount')}}
                            </template>
                            <b-form-input
                                readonly
                                plain
                                id="allocated_amount"
                                v-model="budget_details.allocated_amount"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </b-col>
                  <!-- Available Balance -->
                  <b-col sm="4">
                      <ValidationProvider name="Available Balance" vid="available_balance" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          label-for="available_balance"
                          slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                            {{$t('external_research.available_balance')}}
                            </template>
                            <b-form-input
                                readonly
                                plain
                                id="available_balance"
                                v-model="budget_details.available_balance"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </b-col>
                  <!-- Requisition Amount -->
                  <b-col sm="4">
                      <ValidationProvider name="Requisition Amount" vid="requisition_amount" rules="required|min_value:1|max_value:@available_balance">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          label-for="requisition_amount"
                          slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                              {{$t('external_research.requisition_amount')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                plain
                                id="requisition_amount"
                                v-model="budget_details.requisition_amount"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </b-col>
                  <b-col>
                      <div class="text-right mb-2">
                          <b-button type="submit" variant="success">{{ $t('globalTrans.add')}}</b-button>
                      </div>
                  </b-col>
                </b-row>
              </b-form>
          </ValidationObserver>
          <hr>
          <ValidationObserver ref="form2" v-slot="{ handleSubmit, reset }">
              <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                <b-row>
                    <b-col sm="12">
                        <b-table-simple small bordered>
                        <thead class="text-white bg-primary">
                            <tr>
                                <th class="text-center" width="100px">{{ $t('globalTrans.sl_no') }}</th>
                                <th scope="col">{{ $t('research_manage.budget_type') }}</th>
                                <th scope="col">{{ $t('external_research.budget_head') }}</th>
                                <th scope="col">{{ $t('globalTrans.fiscal_year') }}</th>
                                <th scope="col">{{ $t('external_research.allocation_amount') + ' ' + $t('globalTrans.taka') }}</th>
                                <th scope="col">{{ $t('external_research.available_balance') + ' ' + $t('globalTrans.taka') }}</th>
                                <th scope="col">{{ $t('external_research.requisition_amount') + ' ' + $t('globalTrans.taka') }}</th>
                                <th class="text-center">{{ $t('globalTrans.action') }}</th>
                            </tr>
                        </thead>
                        <b-tbody>
                            <b-tr v-for="(item, index) in fund_request.details" :key="index">
                                <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                                <b-td>{{ getBudgetTypeName(item.budget_type_id) }}</b-td>
                                <b-td>{{ getBudgetName(item.budget_head_id) }}</b-td>
                                <b-td>{{ getExpenseYearName(item.fiscal_year_id) }}</b-td>
                                <b-td>{{ Number.isInteger(parseFloat(item.allocated_amount)) ? ($n(item.allocated_amount) + (currentLocale === 'bn' ? '.০০' : '.00')) : $n(item.allocated_amount) }}</b-td>
                                <b-td>{{ Number.isInteger(parseFloat(item.available_balance)) ? ($n(item.available_balance) + (currentLocale === 'bn' ? '.০০' : '.00')) : $n(item.available_balance) }}</b-td>
                                <b-td>
                                  <ValidationProvider name="Requisition Amount" :vid="`requisition_amount${index}`" :rules="`required|min_value:1|max_value:${item.available_balance}`">
                                    <b-form-group
                                      class="m-0"
                                      label-for="requisition_amount"
                                      slot-scope="{ valid, errors }"
                                    >
                                      <b-form-input
                                          plain
                                          @input="getGrandTotal()"
                                          :id="`requisition_amount${index}`"
                                          v-model="item.requisition_amount"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      </b-form-input>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-td>
                                <b-td class="text-center">
                                    <b-button type="button" @click="deleteItem(index)" class="btn btn-danger" size="sm"><i class="fa fa-trash m-0"></i></b-button>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="nodata">
                                <td colspan="8" class="text-center">
                                    <span class="text-black">{{ (currentLocale === 'bn') ? 'কোন তথ্য পাওয়া যায়নি!' : 'No Data Found' }}</span>
                                </td>
                            </b-tr>
                            <b-td colspan="6">
                                <p class="text-right m-0">{{ $t('external_research.total_requisition_amount') }}</p>
                            </b-td>
                            <b-td colspan="2" class="bg-warning">
                              {{ Number.isInteger(parseFloat(fund_request.total_requisition)) ? ($n(fund_request.total_requisition) + (currentLocale === 'bn' ? '.০০' : '.00')) : $n(fund_request.total_requisition) }}
                            </b-td>
                        </b-tbody>
                        </b-table-simple>
                    </b-col>
                </b-row>
                <hr>
                <b-row>
                  <b-col sm="4">
                      <ValidationProvider name="Attachment" vid="attachment" rules="required">
                        <b-form-group
                        class="row"
                        label-cols-sm="12"
                        label-for="attachment"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{ $t('external_research.expense_breakdown')}}<span class="text-danger">*</span>
                        </template>
                        <b-form-file
                        id="attachment"
                        v-model="fund_request.attachment"
                        v-on:change="onFileChange"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-file>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col sm="4">
                    <ValidationProvider name="Remarks" vid="remarks" >
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="remarks"
                            >
                              <template v-slot:label>
                                  {{$t('certify_form.remarks')}}
                              </template>
                              <b-form-textarea
                                  id="remarks_en"
                                  v-model="fund_request.remarks"
                              >
                              </b-form-textarea>
                        </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <div class="text-right mt-2">
                  <!-- Disabling Save if available_balance <= 0 -->
                  <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                  <!-- <b-button type="submit" :disabled="fund_request.available_balance <= 0 ? true : false" variant="primary" class="mr-2">{{ saveBtnName }}</b-button> -->
                  <b-button variant="danger" @click="$bvModal.hide('modal-3')">{{ $t('globalTrans.cancel') }}</b-button>
                </div>
              </b-form>
          </ValidationObserver>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { fundRequestStoreApi, fundAllocatedAmountPendingCheckApi } from '../../../api/routes'

export default {
  props: {
    projectList: {
      type: Array,
      required: true,
      default () {
        return []
      }
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    // this.expenseYearList = this.getExpenseYearList()
  },
  data () {
    return {
      saveBtnName: this.$t('globalTrans.save'),
      formLoader: false,
      nodata: true,
      budget_details: {
          budget_type_id: 0,
          budget_head_id: 0,
          fiscal_year_id: 0,
          allocated_amount: 0,
          available_balance: 0,
          requisition_amount: ''
      },
      fund_request: {
        proposal_main_id: 0,
        proposal_auto_id: '',
        thematic_area_id: 0,
        attachment: [],
        remarks: '',
        thematic_area_title: '',
        project_title: '',
        total_requisition: 0,
        details: []
      },
      budgetHeadList: [],
      fiscalYearList: []
    }
  },
  computed: {
    currentLocale: function () {
      return this.$i18n.locale
    },
    budgetTypeList: function () {
        return this.$store.state.ExternalAgriResearch.agriResearchCommonObj.budgetTypeList.filter(item => item.status === 1).map(el => {
            return Object.assign({}, el, { value: el.value, text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
        })
    }
    // fiscalYearList: function () {
    //    return this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList
    // }
  },
  watch: {
    'fund_request.proposal_main_id': function (newVal, oldVal) {
      this.getProposalDetails()
      this.getAllocatedAmount()
    },
    'budget_details.budget_head_id': function (newVal, oldVal) {
      this.getAllocatedAmount()
    },
    'budget_details.fiscal_year_id': function (newVal, oldVal) {
      this.getAllocatedAmount()
    },
    'budget_details.budget_type_id': function (newValue) {
        this.getBudgetHeadList()
    },
    currentLocale: function (newVal, oldVal) {
        if (newVal !== oldVal) {
            this.getBudgetHeadList()
            this.getExpenseYearList()
        }
    }
  },
  methods: {
      getBudgetHeadList () {
          this.budgetHeadList = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.budgetHeadList.filter(item => item.status === 1 && item.budget_type_id === parseInt(this.budget_details.budget_type_id)).map(obj => {
          if (this.$i18n.locale === 'bn') {
                  return { value: obj.value, text: obj.text_bn }
              } else {
                  return { value: obj.value, text: obj.text_en }
              }
          })
      },
      getBudgetName (Id) {
          const Obj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.budgetHeadList.find(item => item.value === parseInt(Id))
          if (this.$i18n.locale === 'en') {
                return Obj.text_en
            } else {
                return Obj.text_bn
            }
      },
      getExpenseYearName (Id) {
          const Obj = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.find(item => item.value === Id)
          if (this.$i18n.locale === 'en') {
                return Obj.text_en
            } else {
                return Obj.text_bn
          }
      },
      getBudgetTypeName (Id) {
          const Obj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.budgetTypeList.find(item => item.value === parseInt(Id))
          if (this.$i18n.locale === 'en') {
              return Obj.text_en
          } else {
              return Obj.text_bn
          }
      },
      deleteItem (index) {
          this.fund_request.details.splice(index, 1)
          if (this.fund_request.details.length === 0) {
              this.nodata = true
          }
          this.getGrandTotal()
      },
      getGrandTotal () {
          let sumM = 0
          this.fund_request.details.forEach(function (data) {
              sumM = sumM + parseFloat(data.requisition_amount)
          })
          this.fund_request.total_requisition = parseFloat(sumM)
      },
      onFileChange (e) {
          const format = ['pdf', 'doc', 'docx', 'xls', 'xlsx']
          const input = e.target
          if (input.files && input.files[0]) {
              const ext = input.files[0].name.slice((input.files[0].name.lastIndexOf('.') - 1 >>> 0) + 2)
              const isSupport = format.find(el => el === ext)
              if (typeof isSupport !== 'undefined') {
                const reader = new FileReader()
                reader.onload = (e) => {
                    this.fund_request.breakdown_file = e.target.result
                }
                reader.readAsDataURL(input.files[0])
              } else {
                this.$toast.error({
                    title: 'Invalid File!',
                    message: 'Only pdf, doc, docx, xls and xlsx files are allowed',
                    color: '#D6E09B'
                })
                this.fund_request.breakdown_file = ''
                this.fund_request.attachment = []
              }
          } else {
              this.fund_request.breakdown_file = ''
          }
      },
      async addItem () {
          const budgetType = this.budget_details.budget_type_id
          const budgetHead = this.budget_details.budget_head_id
          const fiscalYear = this.budget_details.fiscal_year_id

          const newData = this.fund_request.details.find(item => item.budget_type_id === budgetType && item.budget_head_id === budgetHead && item.fiscal_year_id === fiscalYear)
          if (typeof newData === 'undefined') {
              this.fund_request.details.push(JSON.parse(JSON.stringify(this.budget_details)))
              this.budget_details = {
                  budget_type_id: 0,
                  budget_head_id: 0,
                  fiscal_year_id: 0,
                  allocated_amount: 0,
                  available_balance: 0
              }
              this.getGrandTotal()
          } else {
            this.$toast.error({
                title: this.$i18n.locale === 'en' ? 'Item already exists' : 'আইটেম ইতিমধ্যেই বিদ্যমান',
                message: '',
                color: '#D6E09B'
            })
          }
          this.$nextTick(() => {
              this.$refs.form1.reset()
          })
          this.nodata = false
      },
      setCurrentFiscalYear (fiscalYearList, date) {
          if (fiscalYearList.length === 0) {
              return
          }
          const currentDate = new Date(date)
          const [month, year] = [currentDate.getMonth() + 1, currentDate.getFullYear()]
          /** Define the year position  */
          const yearPosition = month < 7 ? 5 : 0
          const yearStr = `${year}`
          let currentFiscalYearId = 0
          fiscalYearList.forEach(element => {
              if (element.text_en.indexOf(yearStr) === yearPosition) {
                  currentFiscalYearId = element.value
              }
          })
          return currentFiscalYearId
      },
      getExpenseYearList () {
          if (this.fund_request.from_date !== '' && this.fund_request.to_date !== '') {
              const tmpFiscalList = []
              const fiscalYear = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList
              const startID = this.setCurrentFiscalYear(fiscalYear, this.fund_request.from_date)
              const endId = this.setCurrentFiscalYear(fiscalYear, this.fund_request.to_date)

              let start = false
              fiscalYear.forEach(element => {
                  if (startID === element.value) {
                      start = true
                  }
                  if (start) {
                      tmpFiscalList.push(element)
                  }
                  if (endId === element.value) {
                      start = false
                  }
              })
              return tmpFiscalList
          }
      },
    getProposalDetails () {
      if (this.fund_request.proposal_main_id === 0) {
        this.fund_request.proposal_auto_id = ''
        this.fund_request.thematic_area_id = ''
        this.fund_request.thematic_area_title = ''
        return
      }

      const proposal = this.projectList.find(item => item.value === this.fund_request.proposal_main_id)
      const thematicArea = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.thematicAreaList.find(item => item.value === proposal.thematic_area_id)
      this.fund_request.proposal_auto_id = proposal.proposal_auto_id
      this.fund_request.thematic_area_id = thematicArea.value
      this.fund_request.thematic_area_title = this.$i18n.locale === 'bn' ? thematicArea.text_bn : thematicArea.text_en
      this.fund_request.from_date = proposal.from_date
      this.fund_request.to_date = proposal.to_date
      this.fiscalYearList = []
      this.fiscalYearList = this.getExpenseYearList()
    },
    /**
     * Aasynchronous Methods
     */
    async getAllocatedAmount () {
      /**
       * Changes in proposal_main_id, fiscal_year_id, budget_head_id
       * Will make an API Request to get Allocated Amount
       */
      if (
        this.fund_request.proposal_main_id <= 0 ||
        this.budget_details.fiscal_year_id <= 0 ||
        this.budget_details.budget_type_id <= 0 ||
        this.budget_details.budget_head_id <= 0
      ) {
        return false
      }
      this.formLoader = true
      const params = {
        project_id: this.fund_request.proposal_main_id,
        fiscal_year_id: this.budget_details.fiscal_year_id,
        budget_type_id: this.budget_details.budget_type_id,
        budget_head_id: this.budget_details.budget_head_id
      }
      const result = await RestApi.getData(agriResearchServiceBaseUrl, fundAllocatedAmountPendingCheckApi, params)

      if (result.success && result.data) {
          this.budget_details.allocated_amount = result.data.allocation_amount
          this.budget_details.available_balance = result.data.available_balance

          /** No Avaialable Balance */
          if (result.data.available_balance <= 0) {
            this.$toast.error({
              message: 'Budget Not available.'
            })
          }
        this.formLoader = false
      }

      /** Pending */
      if (!result.success && result.errors) {
        if (result.errors.pending) {
          this.budget_details.allocated_amount = 0
          this.budget_details.available_balance = 0

          this.$toast.error({
            title: 'Error',
            message: result.errors.pending
          })
        }
        this.formLoader = false
      }
      this.formLoader = false
    },
    async saveData () {
      var check = await this.$refs.form.validate()
      if (check) {
            this.formLoader = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }
            result = await RestApi.postData(agriResearchServiceBaseUrl, fundRequestStoreApi, this.fund_request)

            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)
            if (result.success) {
              this.$store.dispatch('AgriResearch/mutateCommonObj', { hasDropdownLoaded: false })
              this.$toast.success({
                title: this.$t('globalTrans.success'),
                message: this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
              })
              this.$bvModal.hide('modal-3')
            } else {
              this.$refs.form.setErrors(result.errors)
              this.$refs.form2.setErrors(result.errors)
              this.$toast.error({
                title: this.$t('globalTrans.error'),
                message: this.$t('globalTrans.form_error_msg'),
                color: '#D6E09B'
              })
            }
            this.formLoader = false
      }
    }
  }
}
</script>
